/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

/*
import React from "react";


import PageTransition from "./src/components/PageTransition.js";
export const wrapPageElement = ({element, props}) => {
    return <PageTransition {...props}>{element}</PageTransition>;
  };
*/


// Always start at the top of the page on a route change.
// More investigation needed for a better solution. See https://github.com/gatsbyjs/gatsby/pull/3483

export const onRouteUpdate = () => {
  if (typeof window !== `undefined`) { window.scrollTo(0, 0)}
}

export const shouldUpdateScroll = args => {
   return false;
};