// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onderwijs-js": () => import("./../../../src/pages/onderwijs.js" /* webpackChunkName: "component---src-pages-onderwijs-js" */),
  "component---src-pages-ontwikkeling-js": () => import("./../../../src/pages/ontwikkeling.js" /* webpackChunkName: "component---src-pages-ontwikkeling-js" */),
  "component---src-pages-organisatie-js": () => import("./../../../src/pages/organisatie.js" /* webpackChunkName: "component---src-pages-organisatie-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */)
}

